import { ActionAfterViewed } from '@cian/action-after-viewed-component';
import { useBooleanState } from '@cian/react-utils';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { CardSectionNew } from 'shared/components/CardSectionNew';
import { TourFrame } from 'shared/components/TourFrame';
import { TourLayout } from 'shared/components/TourLayout';
import { TourWrapper } from 'shared/components/TourWrapper';
import { selectOfferCategory } from 'shared/selectors';
import { selectTourData } from 'shared/selectors/offerData/tour/selectTourData';

import { useOnOpenTourHandler } from './hooks';
import { track3DTourBlockClick, track3DTourBlockShow } from './tracking';
import { sendUXSEvent } from '../../utils/uxs';
import { FullscreenWrapperContainer } from '../FullscreenWrapper';

export function TourContainer() {
  const { state: isOpen, setTrue: openTour, setFalse: closeTour } = useBooleanState();

  const { ref, onTourOpen } = useOnOpenTourHandler();

  const { tourLink, tourLinkWithoutFullscreen, tourBannerImageUrl } = useSelector(selectTourData);

  const offerCategory = useSelector(selectOfferCategory);

  const handleTourShow = React.useCallback(() => {
    openTour();
    track3DTourBlockClick();

    if (offerCategory === 'newBuildingFlatSale') {
      setTimeout(() => {
        sendUXSEvent('novostroiky_3d_desktop');
      }, 3000);
    }
  }, [offerCategory, openTour]);

  if (!tourLink || !tourBannerImageUrl) {
    return null;
  }

  return (
    <>
      <ActionAfterViewed callback={track3DTourBlockShow}>
        <CardSectionNew title="3D-тур" data-name="TourSection" overflowHidden>
          <TourWrapper>
            <TourLayout onClick={handleTourShow} tourBannerImageUrl={tourBannerImageUrl} />
          </TourWrapper>
        </CardSectionNew>
      </ActionAfterViewed>
      {isOpen && (
        <FullscreenWrapperContainer
          isFullScreenInitialState
          onFullscreenOpen={onTourOpen}
          onFullscreenClose={closeTour}
        >
          <TourFrame ref={ref} tourUrl={tourLinkWithoutFullscreen} />
        </FullscreenWrapperContainer>
      )}
    </>
  );
}
