import { UIHeading1, UIText1 } from '@cian/ui-kit';
import { IconActionClose16 } from '@cian/ui-kit-design-tokens/icons';
import cx from 'clsx';
import * as React from 'react';

import * as styles from './FullscreenWrapper.css';
import { invokeRefFunction } from './utils';
import { FullscreenButton } from '../FullscreenButton';

export interface IFullscreenWrapperProps {
  children?: React.ReactNode;
  builderName: string;
  callbackButton?: React.ReactNode;
  contactsButton?: React.ReactNode;
  onFullscreenClose?(): void;
  onFullscreenOpen?(): void;
  parentName: string;
  price: string;
  pricePerMeter: string;
  title: string;
  isFullScreenInitialState?: boolean;
  isRedesigned?: boolean;
}

export function FullscreenWrapper({
  children,
  builderName,
  callbackButton,
  contactsButton,
  onFullscreenClose,
  onFullscreenOpen,
  parentName,
  price,
  pricePerMeter,
  title,
  isFullScreenInitialState,
  isRedesigned,
}: IFullscreenWrapperProps) {
  const [isFullScreen, setIsFullScreen] = React.useState(isFullScreenInitialState);
  const latestCloseHandler = React.useRef<() => void>();

  const handleEscapePress = React.useCallback((event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      invokeRefFunction(latestCloseHandler);
    }
  }, []);

  const handleFullScreenOpen = React.useCallback(async () => {
    setIsFullScreen(true);
    document.body.classList.add(styles['no-scroll']);
    document.addEventListener('keydown', handleEscapePress);

    if (onFullscreenOpen) {
      onFullscreenOpen();
    }
  }, [onFullscreenOpen, handleEscapePress]);

  const handleFullScreenClose = React.useCallback(() => {
    setIsFullScreen(false);
    document.body.classList.remove(styles['no-scroll']);
    document.removeEventListener('keydown', handleEscapePress);

    if (onFullscreenClose) {
      onFullscreenClose();
    }
  }, [onFullscreenClose, handleEscapePress]);

  React.useEffect(() => {
    latestCloseHandler.current = handleFullScreenClose;
  });

  React.useEffect(() => {
    if (isFullScreenInitialState) {
      handleFullScreenOpen();
    }
  }, []);

  React.useEffect(
    () => () => {
      document.body.classList.remove(styles['no-scroll']);
      document.removeEventListener('keydown', handleEscapePress);
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <div className={cx(styles['container'], isFullScreen && styles['fullscreen'], !isRedesigned && styles['uplayer'])}>
      {!isFullScreen && (
        <div className={styles['fullscreen-button']}>
          <FullscreenButton onClick={handleFullScreenOpen} />
        </div>
      )}
      <div className={styles['view']}>{children}</div>

      <div className={cx(styles['info'], !isFullScreen && styles['hidden'])}>
        <button
          className={styles['close-button']}
          type="button"
          onClick={handleFullScreenClose}
          data-testid="close-btn"
          data-name="TourPopupCloseButton"
        >
          <IconActionClose16 color="gray60_100" />
        </button>

        <div className={styles['info-section']}>
          {parentName && (
            <div className={styles['info-block']}>
              <UIText1>{parentName}</UIText1>
            </div>
          )}
          <div className={styles['info-block']}>
            <UIText1 as="h3" fontWeight="bold">
              {title}
            </UIText1>
          </div>
          <div className={styles['info-block']}>
            <UIHeading1 as="p">
              <span {...{ itemProp: 'price', content: price }}>{price}</span>
              <span {...{ itemProp: 'priceCurrency', content: 'RUB' }} />
            </UIHeading1>
          </div>
          {pricePerMeter && (
            <div className={styles['info-block']}>
              <UIText1>{pricePerMeter}</UIText1>
            </div>
          )}
        </div>

        <UIText1>
          Узнайте больше подробностей о&nbsp;квартире и&nbsp;жилом комплексе{' '}
          {builderName && ` у\xA0застройщика ${builderName}`}
        </UIText1>

        <div className={styles['contact-button']}>{contactsButton}</div>
        <div className={styles['contact-button']}>{callbackButton}</div>
      </div>
    </div>
  );
}
