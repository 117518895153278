import { numberToPrettyString } from '@cian/utils';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { IAgent } from '../../types/offerData';
import { isFromBuilder } from '../../utils/agent';
import { CurrencySymbol } from '../../utils/formatters';
import { getOfferAgentName } from '../../utils/getOfferAgentName';
import { getOfferTitle, getSquareInformation, IParent, ParentType } from '../../utils/offer_helpers';

export const formatParentName = (parent?: IParent, withInPrefix?: boolean): string => {
  let prefix = '';

  if (!parent) {
    return '';
  }

  if (parent.type === ParentType.JK) {
    prefix = withInPrefix ? 'в ЖК' : 'ЖК';
  } else if (parent.type === ParentType.KP) {
    prefix = withInPrefix ? 'в коттеджном посёлке' : 'Коттеджный посёлок';
  } else if (parent.type === ParentType.Coworking) {
    prefix = withInPrefix ? 'в коворкинге' : 'Коворкинг';
  }

  return `${prefix} «${parent.name}»`.trim();
};

export const formatTitle = (offer: IOfferSchema) => {
  let title = getOfferTitle(offer);
  const squareInformation = getSquareInformation(offer);

  if (squareInformation) {
    title += `, ${squareInformation}`;
  }

  return title;
};

export const formatPrice = (offer: IOfferSchema) => {
  const { bargainTerms } = offer;

  return `${numberToPrettyString(Math.round(bargainTerms.prices.rur))} ${CurrencySymbol.Rur}`;
};

export const getDeveloperName = (offer: IOfferSchema, agent: IAgent | null) => {
  if (isFromBuilder(offer) && agent) {
    return getOfferAgentName(offer, agent);
  }

  return '';
};
