import { useEventCallback } from '@cian/react-utils';
import { useMemo, useRef } from 'react';

export const useOnOpenTourHandler = () => {
  const tourFrameRef = useRef<HTMLIFrameElement>(null);

  const handleFullscreenOpen = useEventCallback(() => {
    tourFrameRef.current?.focus();
  });

  return useMemo(() => ({ ref: tourFrameRef, onTourOpen: handleFullscreenOpen }), [handleFullscreenOpen]);
};
