import { Label, Tooltip, UIText2 } from '@cian/ui-kit';
import { FC } from 'react';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { Link } from 'shared/components/Link';
import { useIsElectronicTradingEnabled } from 'shared/hooks/useIsElectronicTradingOffer';
import { selectAgentLists } from 'shared/selectors/offerData/selectAgentLists';

import styles from './AuctionLabel.css';
import { useApplicationContext } from '../ApplicationContext';

export const AuctionLabelContainer: FC = () => {
  const { config } = useApplicationContext();

  const agentLists = useSelector(selectAgentLists);

  /**
   * @todo Удалить RS
   * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
   */
  const isElectronicTradingEnabled = useIsElectronicTradingEnabled();

  const isAvailable =
    /**
     * @todo Удалить RS
     * @description Данный функционал появился в задаче CD-189160, будет удалён в задаче CD-190741
     * Удалить переменную isElectronicTradingEnabled из условия
     */
    isElectronicTradingEnabled && agentLists.includes('tradeRealty');

  if (!isAvailable) {
    return null;
  }

  const legalDocsUrl = config.getStrict<string>('electronicTrading.legalDocsUrl');

  return (
    <Tooltip
      arrow={false}
      placement="bottom"
      theme="white"
      title={
        <div className={styles['content']} data-testid="electronic-trading.tooltip">
          <UIText2>
            Объект реализуется через проведение <br />
            торгов в соответствии с ГК РФ
          </UIText2>
          <UIText2 color="text-main-default">
            <Link url={legalDocsUrl}>
              <UIText2 color="current_color">Справочная информация</UIText2>
            </Link>
          </UIText2>
        </div>
      }
    >
      <Label background="overlay-control-dark" color="decorative-theme-dark" data-testid="publisher-labels.auction">
        Аукцион
      </Label>
    </Tooltip>
  );
};
