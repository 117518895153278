import { ca } from '@cian/analytics';

export function track3DTourBlockClick() {
  ca('eventSite', {
    action: 'Click',
    category: 'card',
    label: '3dtour_viewer',
    name: 'oldevent',
    useLastProducts: true,
  });
}

export function track3DTourBlockShow() {
  ca('eventSite', {
    action: 'Show',
    category: 'card',
    label: '3dtour_viewer',
    name: 'oldevent',
    useLastProducts: true,
  });
}
