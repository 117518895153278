import * as React from 'react';
import { useSelector } from 'react-redux';

import { CallBackButtonContainer } from 'shared/containers/CallBackButton';
import { ContactsButtonContainer } from 'shared/containers/ContactsButton';
import { selectOfferAgent, getOfferParent, offerSelector } from 'shared/selectors';

import { formatParentName, formatPrice, formatTitle, getDeveloperName } from './utils';
import { FullscreenWrapper, IFullscreenWrapperProps } from '../../components/FullscreenWrapper';
import { getPricePerMeter } from '../../utils/getPricePerMeter';

type TFullscreenWrapperContainerProps = Pick<
  IFullscreenWrapperProps,
  'onFullscreenClose' | 'onFullscreenOpen' | 'children' | 'isFullScreenInitialState' | 'isRedesigned'
>;

export function FullscreenWrapperContainer({
  isRedesigned,
  onFullscreenClose,
  onFullscreenOpen,
  children,
  isFullScreenInitialState,
}: TFullscreenWrapperContainerProps) {
  const offer = useSelector(offerSelector);
  const parent = useSelector(getOfferParent);
  const agent = useSelector(selectOfferAgent);

  const parentName = React.useMemo(() => formatParentName(parent), [parent]);
  const title = React.useMemo(() => formatTitle(offer), [offer]);
  const price = React.useMemo(() => formatPrice(offer), [offer]);
  const pricePerMeter = React.useMemo(() => getPricePerMeter(offer) || '', [offer]);
  const builderName = React.useMemo(() => getDeveloperName(offer, agent), [offer, agent]);

  return (
    <FullscreenWrapper
      isRedesigned={isRedesigned}
      callbackButton={<CallBackButtonContainer placeType="3dtour" />}
      contactsButton={<ContactsButtonContainer placeType="3dtour" />}
      builderName={builderName}
      onFullscreenClose={onFullscreenClose}
      onFullscreenOpen={onFullscreenOpen}
      parentName={parentName}
      price={price}
      pricePerMeter={pricePerMeter}
      title={title}
      isFullScreenInitialState={isFullScreenInitialState}
    >
      {children}
    </FullscreenWrapper>
  );
}
