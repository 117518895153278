import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { getAgentName } from './agent';
import { createAgentIdName } from './createAgentIdName';
import { IAgent } from '../types/offerData';

export const getOfferAgentName = (offer: IOfferSchema, agent: IAgent | null) => {
  if (agent) {
    const name = agent.isSubAgent ? agent.companyName : getAgentName(agent, offer);
    const agentId = createAgentIdName(agent);

    return agent.bank || name || agentId;
  }

  return '';
};
