import { numberToPrettyString } from '@cian/utils';

import { IOfferSchema } from 'shared/types/api-models/offer-card/v1/get-offer-data';

import { FDealType, getDealType } from './deal_type';
import { getLandAreaUnitType } from './offer_helpers';
import { FOfferType, getOfferType, isCommercial } from './offer_type';

export const getPricePerMeter = (offer: IOfferSchema) => {
  const { areaParts } = offer;
  const dealType = getDealType(offer);
  const offerType = getOfferType(offer);

  if (offerType & FOfferType.Residential) {
    if (dealType === FDealType.Sale) {
      const { bargainTerms } = offer;

      let price: string | undefined;

      if (offerType === FOfferType.Land) {
        if (offer.land && offer.land.area) {
          price = `${numberToPrettyString(
            Math.round(bargainTerms.prices.rur / Number(offer.land.area)),
          )} \u20bd/${getLandAreaUnitType(offer.land)}`;
        }
        /**
         * Для комнаты цену за квадратный метр считаем от площади этой комнаты.
         */
      } else if (offerType === FOfferType.Room) {
        if ((offer.allRoomsArea && offer.roomsForSaleCount && offer.roomsForSaleCount > 0) || offer.roomArea) {
          /**
           *  Из апи могут приходить 3 формата строки:
           *  плюс используется для обозначения смежных комнат
           *  минус используется для обозначения изолированных комнат
           *  через пробел
           *
           *  числа разделяются запятыми
           */
          const rooms =
            offer.allRoomsArea &&
            offer.allRoomsArea.replace(/,/g, '.').replace(/\+/g, '-').replace(/\s/g, '-').split('-');

          if (rooms && rooms.length > 1) {
            const roomsSquare = rooms.reduce((acc, current) => acc + Number(current), 0);
            if (!isNaN(roomsSquare)) {
              price = `${numberToPrettyString(Math.round(bargainTerms.prices.rur / roomsSquare))} \u20bd/м\u00b2`;
            }
          } else if (rooms && rooms.length > 0 && Number(rooms[0]) !== 0) {
            price = `${numberToPrettyString(Math.round(bargainTerms.prices.rur / Number(rooms[0])))} \u20bd/м\u00b2`;
          } else if (offer.roomArea) {
            price = `${numberToPrettyString(
              Math.round(bargainTerms.prices.rur / Number(offer.roomArea)),
            )} \u20bd/м\u00b2`;
          }
        }
      } else if (offer.totalArea) {
        price = `${numberToPrettyString(Math.round(bargainTerms.prices.rur / Number(offer.totalArea)))} \u20bd/м\u00b2`;
      }

      return price;
    }
  } else if (isCommercial(offerType)) {
    let price: string | undefined;

    if (areaParts && areaParts.length > 0) {
      let preparedByPrice;
      if (dealType & FDealType.Rent) {
        preparedByPrice = areaParts.map(i => i.price && i.area && (i.price * 12) / Number(i.area));
      } else {
        preparedByPrice = areaParts.map(i => i.price && i.area && i.price / Number(i.area));
      }

      const minAreaPrice = Math.min.apply(null, preparedByPrice);
      const paymentPeriodTitle = dealType & FDealType.Rent ? ' в год' : '';
      price = `от ${numberToPrettyString(Math.round(minAreaPrice))} \u20bd за м\u00b2${paymentPeriodTitle}`;
    } else if (dealType & FDealType.Rent) {
      if (offer.pricePerUnitAreaPerYearRur) {
        if (offerType === FOfferType.CommercialLand) {
          price = `${numberToPrettyString(
            Math.round(offer.pricePerUnitAreaPerYearRur),
          )} \u20bd за ${getLandAreaUnitType(offer.land)} в год`;
        } else {
          price = `${numberToPrettyString(Math.round(offer.pricePerUnitAreaPerYearRur))} \u20bd за м\u00b2 в год`;
        }
      }
    } else if (dealType === FDealType.Sale) {
      const { bargainTerms } = offer;
      if (offer.pricePerUnitAreaRur && offerType === FOfferType.CommercialLand) {
        price = `${numberToPrettyString(Math.round(offer.pricePerUnitAreaRur))} \u20bd за ${getLandAreaUnitType(
          offer.land,
        )}`;
      } else if (bargainTerms.priceType !== 'all') {
        const cost = numberToPrettyString(bargainTerms.prices.rur);
        const unit = bargainTerms.priceType === 'squareMeter' ? '\u20bd за м\u00b2' : '\u20bd';

        price = `${cost} ${unit}`;
      } else if (offer.pricePerUnitAreaRur) {
        price = `${numberToPrettyString(offer.pricePerUnitAreaRur)} \u20bd за м\u00b2`;
      } else if (offer.totalArea) {
        const totalArea = parseFloat(offer.totalArea);

        if (totalArea > 0) {
          const pricePerMeter = Math.round(offer.bargainTerms.prices.rur / totalArea);

          price = `${numberToPrettyString(pricePerMeter)} \u20bd за м\u00b2`;
        }
      }
    }

    return price;
  }

  return undefined;
};
