import { IconActionFullscreen16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './FullscreenButton.css';

interface IFullscreenButtonProps {
  onClick?(event: React.MouseEvent<HTMLButtonElement>): void;
}

export function FullscreenButton({ onClick }: IFullscreenButtonProps) {
  return (
    <button data-testid="FullscreenButton" className={styles['fullscreen-button']} onClick={onClick} type="button">
      <IconActionFullscreen16 color="icon-inverted-default" />
    </button>
  );
}
